import { whiteColor, primaryColour, dangerColor } from "assets/jss/material-dashboard-react";
import MinusPrimary from "assets/img/minus-primary.svg"

const integrationStyle = theme => ({
  integrationIndex: {
    zIndex: 1,
    position: "absolute",
    width:"100vw",
    height:"100vh",
    top: 0,
    left: 0,
  },
  colorsWrap: {
    '& *': {
      zIndex: 200
    }
  },
  integrationComponent: {
    width: "100vw",
    height: "100vh",
    background: "linear-gradient(0deg, rgba(195,179,218,1) 0%, rgba(162,198,223,1) 100%)",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  integrationComponent2: {
    width: "100vw",
    height: "100vh",
    background: "linear-gradient(0deg, rgba(195,179,218,1) 0%, rgba(162,198,223,1) 100%)",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center"
  },
  panelWrapper: {
    transform: "translate3d(0, 0, 0)",
    transition: "transform 0.5s ease-in-out",
    position: "absolute",
    zIndex: 3
  },
  panelFullScreen: {
    transform: "translate3d(-460px, 0, 0)"
  },
  panelOne: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 3,
    width: 575,
    borderTopRightRadius: 15,
    height: "100vh",
    backgroundColor: whiteColor,
    boxShadow: "0 3px 10px rgba(0,0,0,0.3)",
    paddingTop: 40,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 20,
    transform: "translate3d(0, 0, 0)",
    transition: "transform 0.5s ease-in-out",
    boxSizing: "border-box",
  },
  panelOneHide: {
    transform: "translate3d(-470px, 0, 0)"
  },
  panelOneWrapper: {
    position: "relative",
    height: "calc(100vh - 305px)",
    paddingTop: 0,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 20,
    marginBottom: 0,
    boxSizing: "border-box",
  },
  sideBarActions: {
    backgroundColor: primaryColour[800],
    paddingTop: 20,
    paddingBottom: 25,
    paddingLeft: 30,
    paddingRight: 30,
  },
  panelTwo: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    width: "100vw",
    height: "100vh",
    backgroundColor: primaryColour[50],
    padding: 0,
    overflow: "auto"
  },
  panelTwoWrapper: {
    paddingTop: 80,
    paddingRight: 15,
    paddingBottom: 40,
    paddingLeft: 15
  },
  closeFab: {
    backgroundColor: whiteColor,
    right: 35,
    top: 35,
    position: "fixed"
  },
  contentTable: {
    maxWidth: 600,
    margin: "0 auto",
    width: "100%",
    position: 'relative',
    [theme.breakpoints.up("xl")]: {
      maxWidth: 900,
    }
  },
  standardForm: {
    marginTop: 20,
    marginRight: 0,
    marginBottom: 20,
    marginLeft: 0,
    position: 'relative'
  },
  paidBlockWrap: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.51)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    left: 0
  },
  paidBlock: {
    borderRadius: 15,
    backgroundColor: whiteColor,
    display: 'flex',
    boxShadow: "0 3px 10px rgba(0,0,0,0.3)",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 320,
    padding: '15px 26px'
  },
  stepperIndex: {
    zIndex: 4,
    position: "fixed",
    bottom: 0,
    left: 0,
    backgroundColor: primaryColour[900],
    width: 575,
    transform: "translate3d(0, 0, 0)",
    transition: "transform 0.5s ease-in-out",
    boxSizing: "border-box",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  stepperIndex2: {
    zIndex: 4,
    position: "fixed",
    bottom: 0,
    left: 0,
    backgroundColor: primaryColour[900],
    width: 575,
    transform: "translate3d(0, 0, 0)",
    transition: "transform 0.5s ease-in-out",
    boxSizing: "border-box",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  integrationStep: {
    cursor: 'pointer!important',
    pointerEvents: 'auto'
  },
  disableStep: {
    opacity: .7,
    cursor: 'disabled!important',
    pointerEvents: 'none'
  },
  invalidStep: {
    '& .MuiStepLabel-iconContainer': {
      backgroundColor: 'red',
      color: 'white'
    }
  },
  disabledDesignScreen: {
    pointerEvents: 'none',
    maxHeight: 'calc(100vh - 400px)',
    overflow: 'hidden'
  },
  stepperHidden: {
    transform: "translate3d(0, 200px, 0)",
  },
  customStepper: {
    backgroundColor: "transparent",
    padding: "25px 0 20px 0",
    "& .MuiStepIcon-root": {
      color: primaryColour[600]
    },
    "& .MuiStepLabel-label": {
      color: primaryColour[600],
      marginTop: 5
    },
    "& .MuiStepConnector-line": {
      borderColor: primaryColour[600]
    },
    "& .MuiStepConnector-active .MuiStepConnector-line, & .MuiStepConnector-completed .MuiStepConnector-line": {
      borderColor: whiteColor
    },
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed": {
      color: whiteColor
    },
    "& .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text, & .MuiStepIcon-root.MuiStepIcon-completed .MuiStepIcon-text": {
      fill: primaryColour[900]
    },
    "& .MuiStepLabel-label.MuiStepLabel-active, & .MuiStepLabel-label.MuiStepLabel-completed": {
      color: whiteColor
    }
  },
  integrationWrapper: {
    position: "realtive"
  },
  subHeadOne: {
    paddingTop: 10,
    paddingBottom: 10,
    color: primaryColour[800],
    marginTop: -5
  },
  customRadio: {
    minWidth: 130,
    "& .MuiButtonBase-root": {
      padding: "4px"
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.54)"
    },
    "& .MuiButtonBase-root + .MuiTypography-root": {
      color: primaryColour[900]
    }
  },
  customDividerOne: {
    marginTop: -10,
    marginBottom: 8
  },
  customDividerTwo: {
    marginTop: 10,
    marginBottom: 10
  },
  buttonSpacing: {
    marginTop: 10,
    marginBottom: 10
  },
  buttonSpacing2: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14
  },
  integrationTable: {
    "& .MuiFab-primary": {
      marginLeft: 10
    },
  },
  categoryItem: {
    zIndex: 3,
    width: 490,
    height: 72.5
  },
  fieldItem: {
    zIndex: 3,
    width: 490,
    height: 72.5
  },
  disabled: {
    opacity: .5,
    pointerEvents: 'none'
  },
  disabledBright: {
    pointerEvents: 'none',
  },
  pointerEventNone: {
    pointerEvents: 'none'
  },
  disabledField: {
    pointerEvents: 'none',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    display: 'flex',
    // '&::before': {
    //   content: `"Upgrade to unlock this option"`,
    //   position: 'absolute',
    //   width: '100%',
    //   height: '92%',
    //   backgroundColor: 'rgba(0, 0, 0, .1)',
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   fontWeight: 'bold'
    // },
    '& .Mui-disabled': {
      filter: 'blur(2px)',
      width: '100%',
      height: '100%',
    },
  },
  disabledFieldInput: {
    pointerEvents: 'none',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    display: 'flex',
    '& .MuiGrid-root': {
      marginTop: -6
    },
    '& input.Mui-disabled': {
      filter: 'blur(2px)',
      width: '100%',
      height: '100%'
    },
  },
  enabled: {},
  integrationLogo: {
    maxWidth: '120px',
    height: 'auto'
  },
  integrationLogoWrap: {
    position: 'relative'
  },
  integrationLogoRemoveButton: {
    position: 'absolute',
    right: 15,
    top: 15,
    zIndex: 2
  },
  deleteIconColour: {
    color: dangerColor[0]
  },
  ddArea: {
    backgroundColor: "rgba(0,0,0, 0.05)",
    border: "solid rgba(0,0,0, 0.1) 1px",
    borderRadius: 6,
    textAlign: "center",
    padding: 16,
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.08)",
    }
  },
  blueText: {
    backgroundColor: primaryColour[900],
    color: whiteColor,
    padding: 10,
    maxWidth: 300
  },
  fullContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  typeDropDown: {
    '& .MuiMenu-paper': {
      maxWidth: 600,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  typeDropDownItem: {
    color: primaryColour[900],
    padding: 7,
    fontSize: 18,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  pricingPlans: {
    '& .decrease': {
      backgroundImage: 'url("' + MinusPrimary + '")',
    }
  }
})

export default integrationStyle
