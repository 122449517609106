import React, {useContext, useEffect, useMemo} from "react";
import { useGlobalState } from "hooks/useGlobalState";
// @material-ui/core

import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, TableFooter, IconButton } from "@material-ui/core";
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import Download from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { allowedServices } from 'components/CustomPricing/SelectService.tsx';
import {mapPlansLimitations} from "../../variables/InitState";
// core components

// other components
import AdminHeading from "components/AdminHeading/AdminHeading";
import AdminPanels from "components/AdminPanels/AdminPanels";

import iconVisa from "assets/img/icon-visa.svg";
import iconMatercard from "assets/img/mastercard.svg";
import iconAMEX from "assets/img/amex.svg";



// styles
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle";
import usePrice from "../../hooks/usePrice";
import { round } from "../../helpers/common";
const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(0.5),
  },
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const MyAccount = props => {
  const classes = useStyles();
  const state = useContext(useGlobalState.State);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);


  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);
  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.signedInUser.data.invoices.length - page * rowsPerPage);
  const currencyDetails = useMemo(() => state.availableCurrencies.find(currency => currency.currency === state.currency),
    [
      state.availableCurrencies,
      state.currency,
      state.currentPlan,
      state.currentPlanPriceRec,
      state?.signedInUser?.data?.user?.lastChosenSubscription
    ])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentPlan = useMemo(() => {
    // loop through the subscriptions and get the relevant one
    const allPlans = [...state.calcumatePlans, ...state.calcumatePlansOld, ...state.calcumateCustomPlans];
    let selectedPlan = allPlans.filter(plan => {
      return plan.sidUSD.includes(state.originPricingPlan) || 
      plan.sidAUD.includes(state.originPricingPlan) || 
      plan.sidGBP.includes(state.originPricingPlan) || 
      plan.sidEUR.includes(state.originPricingPlan);
    });
    return selectedPlan[0];
  }, [
    state.calcumatePlans,
    state.calcumatePlansOld,
    state.calcumateCustomPlans,
    state?.currentPricingPlan,
    state?.originPricingPlan,
  ]);

  const price = usePrice({
    plan: currentPlan, 
    currency: state.currency,
    type: state.currentPlanPriceRec.indexOf('yr') > -1,
    number: state.serviceNumber,
  });

  const convertUnixDateToDate = unixTimeStamp => {
    // Convert timestamp to milliseconds
    var date = new Date(unixTimeStamp * 1000);
    return date.getFullYear() + '/' + ('0' + (parseInt(date.getMonth()) + 1)).slice(-2) + '/' + ('0' + (parseInt(date.getDate()))).slice(-2);
  }

  /* eslint-disable */
  useEffect(() => {

    if (state.isLoggedIn && state.signedInUser.token !== '') {
      // set the selected plan in the state

    } else {
      history.replace('/login');
      window.location.reload();
    }

  }, []);

  const cardType = () => {
    let finalCard
    if (state.signedInUser.data.card.brand === "visa") {
      finalCard = iconVisa
    } else if (state.signedInUser.data.card.brand === "mastercard") {
      finalCard = iconMatercard
    } else if (state.signedInUser.data.card.brand === "amex") {
      finalCard = iconAMEX
    } else {
      finalCard = iconVisa
    }
    return (
      finalCard
    )
  }

  const pricingPlanLabel = useMemo(() => {
    let thePlanLabel = 'cancelled';
    if (currentPlan?.thePlanLabel) {
      thePlanLabel = currentPlan.thePlanLabel;
    } else if (currentPlan?.thePlan) {
      thePlanLabel = currentPlan.thePlan;
    }

    if (typeof price !== 'string' && price !== undefined) {
      return (
        <>
          <Typography variant="h6" component="p" className={classes.customH6}>
            {
              currentPlan ?
                `${thePlanLabel} ${currentPlan.showSign ? currencyDetails.sign : ''}${round(price)} ${currentPlan.showSign ? `${state.currentPlanPriceRec.indexOf('yr') > -1 ? '/yr' : '/mo'}` : ''}` :
                'Cancelled'
            }
          </Typography>
          <Typography component="p" style={{fontWeight: 'bold'}}>
            {
              `${(state.serviceNumber && state.service) ? 
                allowedServices.find(service => service.value === state.service)?.label + ' x' + state.originServiceNumber : 
                ''
              }`
            }
          </Typography>
        </>
      );
    }

    return (
      <Typography variant="h6" component="p" className={classes.customH6}>
        {
          currentPlan ?
            `
              ${thePlanLabel} 
            ` :
            'Cancelled'
        }
      </Typography>
    )
  }, [
    price,
    currentPlan,
    state.currentPlanPriceRec,
    currencyDetails,
  ]);

  return (
    <div>
      <AdminHeading
        overline="DASHBOARD"
        heading="My account"
        results={false}
        inMain={true}
        editable={false}
      />
      {state.signedInUser.data.user && state.signedInUser.data.user.email ?
        <Grid container spacing={4} data-cy={'account-details'}>
          <Grid xs={12} md={12} lg={12} item>
            <AdminPanels
              theHeader='Account details'
              headerAction={() => { props.history.push('/admin/my-account/edit') }}
              fabCodeAction={false}
              fabCloneAction={false}
              fabEditAction={false}
              fabDeleteAction={false}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={5} xl={4} className={classes.userBlock}>
                  <AccountCircleRounded />
                  <Typography variant="h6" component="p" className={classes.customH6}>{state.signedInUser.data.user.firstName + ' ' + state.signedInUser.data.user.lastName}</Typography>
                  <Typography variant="body2">{state.signedInUser.data.user.email}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={7} xl={8}>
                  <Typography variant="body2">
                    <strong>Phone:</strong> {state.signedInUser.data.user.phone}<br />
                    {
                      state?.signedInUser?.data?.user?.billing?.billingEmail && (
                        <>
                          <strong>Billing email:</strong> {state.signedInUser.data.user.billing.billingEmail}<br />
                        </>
                      )
                    }
                    <strong>Company name:</strong> {state.signedInUser.data.user.companyName}<br />
                    <strong>Company address:</strong> {state.signedInUser.data.user.billing && state.signedInUser.data.user.billing.address ? state.signedInUser.data.user.billing.address : ''}</Typography>
                </Grid>
              </Grid>
            </AdminPanels>
          </Grid>
          <Grid xs={12} md={state.signedInUser.data?.card ? 6 : 12} lg={state.signedInUser.data?.card ? 6 : 12} item>
            <AdminPanels
              theHeader='My subscription'
              headerAction={() => { props.history.push('/admin/my-account/my-subscription') }}
              fabCodeAction={false}
              fabCloneAction={false}
              fabEditAction={false}
              fabDeleteAction={false}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  { pricingPlanLabel }
                  <Typography variant="body2">{currentPlan ? currentPlan.theFeatureList[0] : 'Add a new plan to activate your integrations'}<br />{currentPlan ? currentPlan.theFeatureList[1] : ''}</Typography>
                </Grid>
              </Grid>
            </AdminPanels>
          </Grid>
          {
            state.signedInUser.data?.card && (
              <Grid xs={12} md={6} lg={6} item>
                <AdminPanels
                  theHeader='Payment method'
                  headerAction={() => { props.history.push('/admin/my-account/payment-method') }}
                  fabCodeAction={false}
                  fabCloneAction={false}
                  fabEditAction={false}
                  fabDeleteAction={false}
                >
                  {/* <img src={cardType()} width={35} /> */}
                  <Typography variant="h6" component="p" className={classes.customH6}>{state.signedInUser.data.card.brand.toUpperCase()}</Typography>
                  <Typography variant="body2">xxxx xxxx xxxx {state.signedInUser.data.card.last4digits}<br /><strong>Expiry:</strong> {state.signedInUser.data.card.expiry}</Typography>
                </AdminPanels>
              </Grid>
            )
          }
          <Grid xs={12} item>
            <AdminPanels
              theHeader='Geographical Locations Setup'
              fabCodeAction={false}
              fabCloneAction={false}
              fabEditAction={false}
              fabDeleteAction={false}
              headerAction={() => { props.history.push('/admin/my-account/locations') }}
              disabled={!restrictions.includes('isLocationEnabled')}
            >
              <Typography variant="body2">Optional, to enable 'nearest facility' to be calculated</Typography>
              {
                state.signedInUser?.data?.user?.locations?.length ? (
                  <Typography variant="body2">
                    Avilable location(s)
                    ({
                      state.signedInUser?.data?.user?.locations?.length
                    })
                  </Typography>
                ) : null
              }
            </AdminPanels>
          </Grid>
          <Grid xs={12} md={12} lg={12} item>
            <AdminPanels
              theHeader='Billing history'
              headerAction={false}
              fabCodeAction={false}
              fabCloneAction={false}
              fabEditAction={false}
              fabDeleteAction={false}
            >
              <TableContainer component={Paper} className={classes.tableMarginTop}>
                <Table aria-label="Payment history">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Invoice</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Paid</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {(rowsPerPage > 0 ? state.signedInUser.data.invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : state.signedInUser.data.invoices).map(invoice => {
                        if (invoice.amount > 0) {
                          return <TableRow key={invoice.number}>
                            <TableCell align="center" component="th" scope="row">{invoice.number}</TableCell>
                            <TableCell align="center">{convertUnixDateToDate(invoice.date)}</TableCell>
                            <TableCell align="center">${invoice.amount}</TableCell>
                            <TableCell align="center">{invoice.paid ? 'Yes' : 'No'}</TableCell>
                            <TableCell align="center"><a href={invoice.pdf}><Download /></a></TableCell>
                          </TableRow>
                        }
                      })}


                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        count={state.signedInUser.data.invoices.length}
                        colSpan={3}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage="Results per page" />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </AdminPanels>
          </Grid>
        </Grid>

        : <CircularProgress />}
    </div>
  );
}

export default MyAccount
