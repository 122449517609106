import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";

import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";

// @material-ui/core
import {
  Button,
  Divider, Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Switch,
} from "@material-ui/core"
import ReorderRoundedIcon from '@material-ui/icons/ReorderRounded';
import DeleteForeverRounded from "@material-ui/icons/DeleteForeverRounded";
import EditRounded from "@material-ui/icons/EditRounded";
import SaveAsRoundedIcon from '@material-ui/icons/SaveRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import TranslateIcon from '@material-ui/icons/Translate';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import AddRounded from '@material-ui/icons/AddRounded';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ListIcon from '@material-ui/icons/List';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// Storage
import {useGlobalState} from "../../../hooks/useGlobalState";
import InitState from '../../../variables/InitState'

// Style
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/layouts/integrationStyle";
import cloneDeep from "lodash.clonedeep";
import kebabCase from 'lodash.kebabcase';
import CircularProgress from "@material-ui/core/CircularProgress";
import {saveIntegration} from "../../../helpers/SaveIntegration";
const useStyles = makeStyles(styles)

const DragHandle = SortableHandle(() => <ReorderRoundedIcon style={{fontSize: 20, position: 'absolute', top: 26}} />);

const SortableWrap = SortableContainer(({ children }) => {
  const classes = useStyles();
  return (
    <Table className={classes.integrationTable} aria-label="Fields setup">
      <TableBody>
        {children}
      </TableBody>
    </Table>
  )
});

const removeField = ({index, type, fields, dispatchState}) => {
  const newFields = cloneDeep(fields)
  newFields.splice(index, 1)
  dispatchState({
    type: 'integration-data',
    payload: {
      [type]: newFields,
    }
  });
}

const SortableItem = SortableElement(({index, type, fields, ...rest}) => {
  const { elemIndex } = rest;
  const [hideSorting, setHideSorting] = useState(false)
  return (
    <TableRow key={index } className={'fieldItem'} style={{position: 'relative'}}>
      <TableCell align="left" size={'small'} className={'dragButton-Cell'}>
        {!hideSorting && <DragHandle/>}
      </TableCell>
      <FieldCell
        {...rest}
        elemIndex={elemIndex}
        fields={fields}
        type={type}
        setHideSorting={setHideSorting}
      />
    </TableRow>
  )
});

const CustomOption = ({option, elemIndex, optionIndex, type, fields}) => {
  const dispatchState = useContext(useGlobalState.Dispatch);
  const updateOption = useCallback((event) => {
    fields[elemIndex].options[optionIndex][event.target.name] = event.target.value ?
      event.target.value :
      event.target.checked;
    dispatchState({
      type: 'integration-data',
      payload: {
        [type]: fields,
      }
    });
  }, [option, elemIndex, optionIndex, type, fields]);

  return (
    <>
      <Checkbox
        checked={option.checked}
        name={'checked'}
        onChange={updateOption}
        inputProps={{'aria-label': 'Enabled option'}}
        color="primary"
      />
      <TextField
        label={'Option'}
        variant="outlined"
        type={'text'}
        onChange={updateOption}
        value={option.option}
        name={'option'}
        fullWidth
      />
      {/* This element need just for blank space */}
      <Tooltip title="Cancel" aria-label="Cancel" style={{visibility: 'hidden'}}>
        <Fab
          color="primary"
          aria-label="Cancel"
          size="small"
        >
          <CancelRoundedIcon style={{fontSize: 20}} />
        </Fab>
      </Tooltip>
      {/* This element need just for blank space */}
      <Tooltip title="Cancel" aria-label="Cancel" style={{visibility: 'hidden'}}>
        <Fab
          color="primary"
          aria-label="Cancel"
          size="small"
        >
          <CancelRoundedIcon style={{fontSize: 20}} />
        </Fab>
      </Tooltip>
      <Tooltip title="Remove option" aria-label="Remove option">
        <Fab
          color="primary"
          aria-label="Remove option"
          size="small"
          onClick={() => {
            const options = [...fields[elemIndex].options];
            options.splice(optionIndex, 1)
            fields[elemIndex].options = options;
            dispatchState({
              type: 'integration-data',
              payload: {
                [type]: fields,
              }
            });
          }}
        >
          <CancelRoundedIcon style={{fontSize: 20}} />
        </Fab>
      </Tooltip>
    </>
  )
}

const FieldCell = props => {
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State);
  const [isEdit, setIsEdit] = useState(false)
  const {label, name, type, helperText, elemIndex, locked, fields} = props;

  const fieldIndex = useMemo(() => {
    return fields.findIndex(field => field.label === label)
  }, [state.integrationData[type]]);

  const [isIncluded, setIsIncluded] = useState(!!fields[fieldIndex].isIncluded);

  useEffect(() => {props.setHideSorting(isEdit)}, [isEdit]);

  const [value, setValue] = useState(fields[fieldIndex].label || '');

  useEffect(() => {
    setValue(fields[fieldIndex].label || '')
  }, [fields])

  const [isValueChanged, setIsValueChanged] = useState(false);
  const save = () => {
    const fields = cloneDeep(props.fields);
    fields[elemIndex].label = value;
    fields[elemIndex].name = fields[elemIndex].name === 'email' ? fields[elemIndex].name : kebabCase(value);
    dispatchState({
      type: 'integration-data',
      payload: {
        [type]: fields,
      }
    });
    setIsEdit(false)
  }

  const updateIncludeOption = () => {
    const fields = cloneDeep(props.fields);
    setIsIncluded(!isIncluded);
    fields[elemIndex].isIncluded = !isIncluded;
    console.log('fields', fields);
    dispatchState({
      type: 'integration-data',
      payload: {
        [type]: fields,
      }
    });
    setIsEdit(false)
  }

  return (
    <TableCell style={{padding: 0}}>
      {
        isEdit ? (
          <>
            <TableCell justify={"center"} alignItems={'center'} className={'fieldEditWrap'}>
              <TextField
                id={`p-${name}`}
                label={label}
                variant="outlined"
                type={type}
                helperText={helperText}
                onChange={e => {
                  setValue(e.target.value)
                  setIsValueChanged(true)
                }}
                value={value}
                name={name}
                error={isValueChanged && fields.find(field => field.label === value)}
                fullWidth
              />
              {
                fields[fieldIndex]?.options && (
                  <Tooltip title="Add option" aria-label="Add option">
                    <Fab
                      color="primary"
                      aria-label="Add option"
                      size="small"
                      onClick={() => {
                        setIsEdit(true)
                        fields[fieldIndex].options.push({option: null, checked: true})
                        dispatchState({
                          type: 'integration-data',
                          payload: {
                            [type]: fields,
                          }
                        });
                        setIsEdit(true)
                      }}
                    >
                      <AddRounded />
                    </Fab>
                  </Tooltip>
                )
              }
              <Tooltip title="Save field" aria-label="Save field">
                <Fab
                  color="primary"
                  aria-label="Save"
                  size="small"
                  onClick={save}
                  disabled={isValueChanged && fields.find(field => field.label === value)}
                >
                  <SaveAsRoundedIcon onClick={save} style={{fontSize: 20}} />
                </Fab>
              </Tooltip>
              <Tooltip title="Cancel" aria-label="Cancel">
                <Fab
                  color="primary"
                  aria-label="Cancel"
                  size="small"
                  onClick={() => setIsEdit(false)}
                >
                  <CancelRoundedIcon style={{fontSize: 20}} />
                </Fab>
              </Tooltip>
            </TableCell>
            {( fields[fieldIndex]?.options && fields[fieldIndex]?.options?.length) ? (
              fields[fieldIndex]?.options.map((option, optionIndex) => (
                <TableCell key={optionIndex} style={{paddingLeft: 0}} justify={"center"} alignItems={'center'} className={'fieldEditWrap'}>
                  <CustomOption
                    option={option}
                    elemIndex={elemIndex}
                    optionIndex={optionIndex}
                    type={type}
                    fields={fields}
                  />
                </TableCell>
              ))
            ) : null}
            </>
        ) : (
          <>
            <TableCell className={'editCellWrap'}>
              <Tooltip title="Edit unit" aria-label="Edit unit">
                <Fab
                  color="primary"
                  aria-label="Edit unit"
                  size="small"
                  onClick={() => setIsEdit(true)}
                >
                  <EditRounded />
                </Fab>
              </Tooltip>
            </TableCell>
            <TableCell align="left" className={'fieldLabel'}>
              <Grid container style={{display: 'flex', alignItems: 'center'}}>
                <Grid item>
                  <Tooltip title={label} aria-label={label} style={{marginRight: 15, cursor: 'default'}} >
                    <Fab
                      color="primary"
                      aria-label={label}
                      size="small"
                    >
                      <FieldIcon type={fields[fieldIndex].type} style={{fontSize: 20}} />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="p">
                    {label}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          </>
        )
      }
      <TableCell align="right">
        <Tooltip title="Include into Report" aria-label="Include into Report">
          <Switch
            checked={isIncluded}
            onChange={updateIncludeOption}
            color="primary"
            name="include-into-report"
            inputProps={{
              "aria-label": "Include field into report",
            }}
          />
        </Tooltip>
      </TableCell>
      {
        (!isEdit && !locked) && (
          <TableCell align="right" className={'removeCellWrap'}>
            <Tooltip title="Remove field" aria-label="Remove field">
              <Fab
                color="primary"
                aria-label="Remove field"
                size="small"
                onClick={() => removeField({index: elemIndex, type, fields, dispatchState})}
              >
                <DeleteForeverRounded style={{fontSize: 20}} />
              </Fab>
            </Tooltip>
          </TableCell>
        )
      }
    </TableCell>
  )
}

const FieldIcon = props => {

  switch (props.type) {
    case 'phone':
      return <PhoneIphoneIcon {...props} />
    case 'time':
      return <AvTimerIcon {...props} />
    case 'date':
    case 'datetime-local':
      return <CalendarTodayIcon {...props} />
    case 'switch':
      return <ToggleOnIcon {...props} />
    case 'languages':
      return <TranslateIcon {...props} />
    case 'list':
      return <ListIcon {...props} />
    default:
      return <TextFieldsIcon {...props} />
  }
}

export default props => {
  const { type, updating, setUpdating, closePanel } = props;
  const state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const fields = useMemo(() => {
    if (state.integrationData[type])
      return state.integrationData[type]
    return InitState.integrationData[type]

  }, [state.integrationDataOptions.selectedIntegration, state.integrationData?.[type]]);

  const newFieldButtonDisabled = useMemo(() => !!fields.find(field => field.name === 'new-field' && field.label === 'New field'),
    [fields]
  )

  const onSortEnd = async ({newIndex, oldIndex}) => {
    const {arrayMoveImmutable} = (await import('array-move'));
    const newFields = arrayMoveImmutable(cloneDeep(fields), oldIndex, newIndex)
    dispatchState({
      type: 'integration-data',
      payload: {
        [type]: newFields,
      }
    });
  };

  const handleSave = async () => {
    setUpdating(true);
    try {
      await saveIntegration(state, false, dispatchState);
      closePanel()
    } catch (e) {
      console.log('Form fields saving error: ', e);
    }
    setUpdating(false);
  };

  const add = fieldType => {
    const validated = ['phone']
    const newFields = cloneDeep(fields)
    const newField = {
      locked: false,
      type: fieldType,
      label: 'New field',
      name: 'new-field',
      validation: validated.includes(fieldType),
    }

    if (fieldType === 'list')
      newField.options = [];

    newFields.push(newField)

    dispatchState({
      type: 'integration-data',
      payload: {
        [type]: cloneDeep(newFields),
      }
    });
    handleClose()
  }

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">Lead Generation Form</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} />
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>
          Choose what you’d like to ask the potential customer
        </Typography>
        <SortableWrap
          onSortEnd={onSortEnd}
          useDragHandle
        >
          {
            (fields || []).map((field, index) => (
              <SortableItem key={`item-${index}`} elemIndex={index} index={index} {...field} type={type} fields={fields} />
            ))
          }
        </SortableWrap>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          onClick={handleClick}
          fullWidth
          disabled={newFieldButtonDisabled}
        >Add additional field</Button>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.typeDropDown}
      >
        <MenuItem className={classes.typeDropDownItem} onClick={() => add('text')}>Text field</MenuItem>
        <MenuItem className={classes.typeDropDownItem} onClick={() => add('date')}>Date field</MenuItem>
        <MenuItem className={classes.typeDropDownItem} onClick={() => add('phone')}>Phone field</MenuItem>
        <MenuItem className={classes.typeDropDownItem} onClick={() => add('time')}>Time field</MenuItem>
        <MenuItem className={classes.typeDropDownItem} onClick={() => add('switch')}>Switch field</MenuItem>
        <MenuItem className={classes.typeDropDownItem} onClick={() => add('list')}>List field</MenuItem>
      </Menu>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          onClick={handleSave}
          fullWidth
          disabled={updating}
        >{updating ? <CircularProgress color='inherit' size={15} /> : 'Save' }</Button>
      </Grid>
    </Grid>
  )
}
