import React, {useContext, useState} from "react";
import axios from 'axios';
//Material Ui
import {useGlobalState} from "../../hooks/useGlobalState";
import {Grid, Typography, Divider, Fab} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloseRounded from "@material-ui/icons/CloseRounded";
//Styles
import stylesTransition from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import {makeStyles} from "@material-ui/core/styles";
//Other component
import {useDropzone} from "react-dropzone";
import BulkImportlocations from '../../assets/downloads/Bulk-Import-Locations.xlsx';
import {regexp} from "../../helpers/validation";

const useStylesTransition = makeStyles(stylesTransition);
const useStyles = makeStyles(styles);

export default ({
  setRows,
  rows
}) => {
  const fileReader = new FileReader();
  const classesTransition = useStylesTransition();
  const classes = useStyles();
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: acceptedFiles => {
      if (acceptedFiles[0]) {
        const file = acceptedFiles[0];
        const handler = mapHandlers?.[file.type];
        if (handler) {
          handler(file)
        }
      }
    },
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv',
    maxFiles: 1
  })

  const xlsxHandler = (file) => {
    import('../../helpers/readExelFile').then(({default: readXlsx}) => {
      readXlsx(file).then((rows) => {
        setRows(rows)
      })
    })
  }

  const csvHandler = (file) => {
    fileReader.onload = function (event) {
      let csvRows = event.target.result;
      csvRows = csvRows.split('\n');
      if (csvRows.length) {
        const newRows = csvRows.map(row => row.split(','))
        setRows(newRows)
      }
    };
    fileReader.readAsText(file);
  }

  const mapHandlers = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': xlsxHandler,
    'text/csv': csvHandler
  }

  const handleClose = () => {

  }

  const Loadedlocations = () => {
    return (
      <Grid style={{
        maxHeight: 350,
        overflow: 'auto'
      }}>
        <Fab
          color="primary"
          aria-label="Edit"
          size="small"
          style={{
            position:'absolute',
            right: 11,
            top: 11
          }}
          onClick={() => {
            setRows(null)
          }}
        >
          <CloseRounded />
        </Fab>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {
              rows.map((row, index) => {
                if (index === 0) {
                  return (
                    <TableHead>
                      <TableRow>
                        {
                          row.map(cell => <TableCell>{cell}</TableCell>)
                        }
                      </TableRow>
                    </TableHead>
                  )
                }
                return (
                  <TableBody>
                    <TableRow
                      key={index}
                      style={
                        isNaN(`${row[2]}`.replace(',', '.'))
                          ? {border: '1px solid red'} : {}
                      }
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {
                        row.map(cell => <TableCell>{cell}</TableCell>)
                      }
                    </TableRow>
                  </TableBody>
                )
              })
            }
          </Table>
        </TableContainer>
      </Grid>
    )
  }

  return (
    <Grid container spacing={0} justify="center" className={classesTransition.cFormContaner} style={{paddingTop: 30}}>
      <Grid item xs={12} sm={12} md={12} className={classesTransition.cWidthLimiter}>
        <Grid item xs={12}>
          <Typography variant="h1" component="h2" style={{fontWeight: 'normal', paddingBottom: 20}}>Import locations</Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Divider className={classes.customDividerOne} style={{ marginBottom: 20 }} />
        </Grid> */}
        <Grid item xs={12}>
          {/* <Typography component={'span'}>Download this <a href={BulkImportlocations} target={'_blank'}>template file</a> and add the latitude / longitude for the location of each of your calculators. Do not change the calculator names in column A. Invalid values won’t be uploaded.</Typography> */}
          <Typography component={'span'}>Download this <a href={BulkImportlocations} target={'_blank'}>template file</a> and add the latitude / longitude for the location of each of your calculators. Invalid values won’t be uploaded.</Typography>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              borderWidth: 1,
              borderStyle: 'solid',
              padding: 25,
              borderRadius: 12,
              position: 'relative',
              marginTop: 20
            }}
            className={{[classes.uploadlocations]: rows && rows.length}}
          >
            {
              rows && rows.length ? (
                  <Loadedlocations />
                ) : (
                <div {...getRootProps()} style={{cursor: 'pointer'}}>
                  <input {...getInputProps()} />
                  <div><p>Import template file.</p></div>
                  <div><p>Drag and drop csv/xlsx file here, or <b><u>select file</u></b></p></div>
                </div>
              )
            }
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography component={'span'}>Note: This upload will replace all existing location data.</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}